import React, { useEffect, useState, useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import AuthContext from "./context/AuthContext";

export default function TopNav() {
  let { user, logoutUser } = useContext(AuthContext);
  const location = useLocation();
  const [loginAsAllow, setLoginAsAllow] = useState(false);

  useEffect(() => {
    setLoginAsAllow(() => {
      if (user) {
        if (user.is_superuser || user.is_symplverify_admin) {
          return true;
        }
      }
      return false;
    });
  }, [user]);

  const devNavBar = () => {
    return (
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="#">SymplForm</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="m-auto">
            <Nav.Link as={NavLink} to="/dev-">
              Home_dev
            </Nav.Link>
            {/* final routes  */}
            <Nav.Link as={NavLink} to="/dev-build-custom">
              Build-Custom
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev-build-from-pdf">
              Build-From-PDF
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dev-build-from-pdf-2">
              Build-From-PDF-2
            </Nav.Link>

            {/* dev routes */}
            <NavDropdown title="Dev" id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/dev-element-sandbox">
                Elements
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-drag-and-drop">
                DragAndDrop
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-pdf-build">
                PDF-Build
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-pdf-build-2">
                PDF-Build-2
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-pdf-edit">
                PDF-Edit
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-drag_and_drop_tutorial">
                drag_and_drop_tutorial
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/dev-task_list">
                TaskList
              </NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link href="/element-sandbox">Elements</Nav.Link> */}
            {/* <Nav.Link href="/drag-and-drop">DragAndDrop</Nav.Link> */}
            {/* <Nav.Link href="/pdf-build">PDF-Build</Nav.Link> */}
            {/* <Nav.Link href="/pdf-build-2">PDF-Build-2</Nav.Link> */}
            {/* <Nav.Link href="/pdf-edit">PDF-Edit</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };
  const prodNavBar = () => {
    return (
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="#">SymplForm</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="m-auto"></Nav>
          <Nav>
            {user ? (
              <NavDropdown menuVariant="dark" title={user && user.username} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={logoutUser} to="/">
                  Logout
                </NavDropdown.Item>
                {loginAsAllow ? (
                  <NavDropdown.Item as={NavLink} to="/login_as">
                    Login As
                  </NavDropdown.Item>
                ) : (
                  ""
                )}
              </NavDropdown>
            ) : (
              <Nav.Link to="/login-page">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };
  const publicNavBar = () => {
    return <></>;
  };

  const getNavBar = () => {
    if (location.pathname.startsWith("/dev-")) {
      return devNavBar();
    } else if (location.pathname.startsWith("/info/") || location.pathname.startsWith("/fill-form")) {
      return publicNavBar();
    } else {
      return prodNavBar();
    }
  };

  return getNavBar();
}
