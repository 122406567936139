import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import 'bootstrap/dist/css/bootstrap.css';
// import { Button } from 'reactstrap';
// import './index.css';
import App from "./App";
// import reportWebVitals from './utils/reportWebVitals';

const initSentry = () => {
  const appMode = process.env.REACT_APP_MODE;
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  if (appMode === "production") {
    Sentry.init({
      dsn: SENTRY_DSN,
      //   integrations: [new BrowserTracing()],
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ],
      environment: appMode,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
    });
    console.log("initSentry");
  }
  else if (appMode === "development_server") {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1.0,
      debug: true,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ],
      environment: appMode,
    })
  }
};
// console.log("Start initSentry");
initSentry();

ReactDOM.render(<App />, document.getElementById("root"));
