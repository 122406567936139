import React, { useState, useEffect } from "react";
import { Container, Button, Col, Row, Modal, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import * as dayjs from "dayjs";
import PdfPreviewInModal from "../../comp/pdf/PreviewInModal";
import NoDraggingLayout from "../../comp/forms/RenderedForm";
import RenderPDF from "../../comp/pdf/utils/RenderPDF";
import { useStoreState, useStoreActions } from "easy-peasy";
import "./FillTheForm.css";
// Toast Messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubmitForm = (props) => {
  let renderPDF = RenderPDF();
  const navigate = useNavigate();
  const form_uuid = useParams();

  // const [patientName, setPatientName] = useState(null);
  // const [patientEmail, setPatientEmail] = useState(null);
  // const [patientPhone, setPatientPhone] = useState(null);
  const [formName, setFormName] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [staffId, setStaffId] = useState(null);
  const [finalFormData, setFinalFormData] = useState([]);
  const [finalPdf, setFinalPdf] = useState([]);

  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  // const [currentFormNumber, setCurrentFormNumber] = useState(props.currentFormNumber);
  // const [totalFormNumber, setTotalFormNumber] = useState(props.totalFormNumber);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState({});
  const [dateSentForm, setDateSentForm] = useState("");
  // const updInputValue = useStoreActions((actions) => actions.updInputValue);
  const formElementsPDF = useStoreState((actions) => actions.formElementsPDF);
  const updFormElementsPDF = useStoreActions((actions) => actions.updFormElementsPDF);
  const setDefaultInputValue = useStoreActions((actions) => actions.setDefaultInputValue);
  const updOnPdfInputValue = useStoreActions((actions) => actions.updOnPdfInputValue);
  const pdfUrl = useStoreState((state) => state.pdfUrl);
  const setPdfUrl = useStoreActions((actions) => actions.setPdfUrl);
  const backendHost = process.env.REACT_APP_BACKEND_HOST;
  const [isSending, setSending] = useState(false);
  const [sendLabel, setSendLabel] = useState(() => {
    if (props.currentFormNumber < props.totalFormNumber) {
      return "Next";
    } else {
      return "Send";
    }
  });

  useEffect(() => {
    // getForm();
    let dayJS = dayjs;
    let timeUnix = dayJS().unix();
    setDateSentForm(timeUnix);
    console.log(dayJS());
  }, []);
  useEffect(() => {
    setSendLabel(() => {
      console.log("setSendLabel");
      if (props.currentFormNumber < props.totalFormNumber) {
        return "Next";
      } else {
        return "Send";
      }
    });
  }, [props.currentFormNumber, props.totalFormNumber]);

  useEffect(() => {
    if (props.data && props.currentFormNumber <= props.totalFormNumber) {
      //
      setFormName(props.data.form_name);
      setLocationId(props.data.location);
      setStaffId(props.data.staff);
      // const currentFormIndex = props.props.currentFormNumber-1
      const form_data = JSON.parse(props.data.form_data[currentFormIndex]);
      const pdf_file_path = props.data.pdf_file[currentFormIndex];
      updFormElementsPDF(form_data);
      setDefaultInputValue();
      setPdfUrl(`${backendHost}${pdf_file_path}`);
    }
    // }, [currentFormIndex]);
  }, [props.data, currentFormIndex]);

  // const getForm = async (event) => {
  //   const response = await fetch(`${backendHost}/api/pdf_form_link/${form_uuid.uuid}`);
  //   let data = await response.json();

  //   if (response.status === 200) {
  //     let formDatas = data.form_data.map((item) => JSON.parse(item));
  //     // let formData = JSON.parse(data.form_data);
  //     console.log({ formDatas });
  //     // updFormElementsPDF(formData);
  //     setLocationId(data.location);
  //     setStaffId(data.staff);
  //     // setFormName(data.form_name);
  //     // _updatePdfUrl(data.pdf_file);
  //     _setUpEachForm(data);
  //   } else {
  //     console.log("Error!");
  //   }
  // };

  // const _setUpEachForm = (data) => {
  //   if (data.form_data.length > 1) {
  //     console.log("Form has multiple pages ");
  //     data.form_data.map((item, index) => {
  //       let formData = JSON.parse(item);
  //       let pdf_file_url = data.pdf_file[index];
  //       let formName = data.form_name[index];
  //       console.log(formData);
  //       console.log(pdf_file_url);
  //       console.log(formName);
  //       updFormElementsPDF(formData);
  //       setFormName(data.form_name[0]);
  //       _updatePdfUrl(data.pdf_file[0]);
  //     });
  //   } else if (data.form_data.length == 1) {
  //     console.log("Single form page");
  //     let formData = JSON.parse(data.form_data[0]);
  //     updFormElementsPDF(formData);
  //     setFormName(data.form_name[0]);
  //     _updatePdfUrl(data.pdf_file[0]);
  //   } else {
  //     console.log("NO data");
  //   }
  // };

  // const _updatePdfUrl = (pdf_file) => {
  //   if (pdf_file && pdf_file != "") {
  //     let url = `${backendHost}${pdf_file}`;
  //     setPdfUrl(url);
  //   } else {
  //     setPdfUrl(pdfUrl);
  //   }
  // };

  const handleChange = (event, elProps) => {
    console.log("handleChange");
    // console.log(elProps.elData.i);
    // console.log(elProps.elData);
    const target = event.target;
    const name = target.name;

    // update PatientName, PatientEmail and PatientPhone
    // if (target.id === "formgr_patient_name") {
    //   setPatientName(target.value);
    // }
    // if (target.id === "formgr_patient_email") {
    //   setPatientEmail(target.value);
    // }

    const value = target.type === "checkbox" ? target.checked : target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    // updInputValue({ i: elProps.elData.id, inputValue: value });
    updOnPdfInputValue({ i: elProps.elData.id, inputValue: value });
  };

  const validateSignature = (formElements) => {
    for (const formElement of formElements) {
        if (formElement.fieldType === 'signature' && formElement.inputValue === '' && formElement.formElementData.required) {
            alert("Please Sign and insert your signature!")
            return false
        }
    }
    return true
  }

  const handleSubmit = async (event) => {
    let [docUrl, blob, height] = [null, null, null];
    let [mergedPdf, mergedBlob] = [null, null];
    const form = event.currentTarget;
    const formData = new FormData(form);
    event.preventDefault();
    if (form.checkValidity() === false || !validateSignature(formElementsPDF)) {
      event.stopPropagation();
      return false
    } else if (form.checkValidity() === true) {
      setSending(true);
      if (sendLabel === "Send") {
        setSendLabel("Sending...");
      } else {
        setSendLabel("Loading...");
      }
      [docUrl, blob, height] = await renderPDF(pdfUrl);

      // collect all data from each Form in one array
      setFinalFormData(() => {
        console.log(finalFormData);
        const key = props.currentFormNumber;
        const f_data = finalFormData.push({ form_key: props.currentFormNumber, form_data: formElementsPDF });
        return finalFormData;
      });

      if (props.currentFormNumber === 1) {
        setFinalPdf(docUrl);
      } else {
        const previousRenderedPdf = finalPdf;
        const presentRenderedPdf = docUrl;
        [mergedPdf, mergedBlob] = await mergePdfPages(previousRenderedPdf, presentRenderedPdf);
        blob = mergedBlob;
        setFinalPdf(mergedPdf);
      }
    }
    console.log("submited form: ", props.currentFormNumber);

    // If form is the last page, send data to server
    if (props.currentFormNumber === props.totalFormNumber) {
      console.log("sending data to server ");
      formData.append("form_name", formName);
      formData.append("location", locationId);
      formData.append("staff", staffId);
      formData.append("date_sent", dateSentForm);
      formData.append("pdf_file_signed", blob, `location_${locationId}_staff_${staffId}.pdf`);
      formData.append("form_data", JSON.stringify(finalFormData));

      console.log({ finalFormData });
      setPdfUrl(mergedPdf);

      const response = await fetch(`${backendHost}/api/pdf_form_link/${form_uuid.uuid}/`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
        },
        body: formData,
      });
      const responseData = await response.json();
      if (response.status === 200) {
        navigate("/info/congratulations");
      } else {
        const errorMessage = responseData.error ?? "Something went wrong";
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return false
      }
      return;
    }

    // clean Form data
    form.reset();
    setSending(false);

    props.setCurrentFormNumber(props.currentFormNumber + 1);
    setCurrentFormIndex(currentFormIndex + 1);
    // Go to top of page
    window.scrollTo(0, 0);
  };

  async function mergePdfPages(previousRenderedPdf, presentRenderedPdf) {
    // const url1 = "https://pdf-lib.js.org/assets/with_update_sections.pdf";
    // const url2 = "https://pdf-lib.js.org/assets/with_large_page_count.pdf";
    const url1 = previousRenderedPdf;
    const url2 = presentRenderedPdf;

    const firstDonorPdfBytes = await fetch(url1).then((res) => res.arrayBuffer());
    const secondDonorPdfBytes = await fetch(url2).then((res) => res.arrayBuffer());

    const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);
    const secondDonorPdfDoc = await PDFDocument.load(secondDonorPdfBytes);

    const pdfDoc = await PDFDocument.create();

    const donor1 = await pdfDoc.copyPages(firstDonorPdfDoc, firstDonorPdfDoc.getPageIndices());
    donor1.forEach((page) => {
      pdfDoc.addPage(page);
    });

    const donor2 = await pdfDoc.copyPages(secondDonorPdfDoc, secondDonorPdfDoc.getPageIndices());
    donor2.forEach((page) => {
      pdfDoc.addPage(page);
    });

    const pdfBytes = await pdfDoc.save();

    var bytes = new Uint8Array(pdfBytes);
    var blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    return [docUrl, blob];
  }

  useEffect(() => {
    // call even 'resize' to fix width of elements in ReactGridLayout
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 30);
  }, [show]);

  return (
    <div className="client-create">
      <Container>
        <Form method="post" encType="multipart/form-data" validated={validated} onSubmit={handleSubmit}>
          <NoDraggingLayout handleChange={handleChange} />

          {/* <input type="hidden" className="pdf-signed" name="pdf_file_signed" id="customPdfSigned" /> */}
          <Row className="justify-content-center">
            <ToastContainer />
            <Button type="submit" className="w-25" variant="primary" disabled={isSending}>
              {sendLabel}
            </Button>
          </Row>
        </Form>
        {/* use next line for testing reason */}
        {/* <PdfPreviewInModal /> */}
      </Container>
    </div>
  );
};

export default SubmitForm;
